import { debounce } from "perfect-debounce";

export class BatchFetcher<ReturnType extends { id: string }> {
  private awaitingIds: {
    id: string;
    resolve: (res: ReturnType | PromiseLike<ReturnType>) => void;
    reject: (reason: any) => void;
  }[] = reactive([]);
  // if the batcher is in the middle of a fetch request
  public pending: boolean = false;
  private timeout: undefined | NodeJS.Timeout | null = null;
  private idleTimeout: undefined | NodeJS.Timeout | null = null;
  private readonly debouncedExecute;

  constructor(
    private batchFn: (ids: string[]) => Promise<ReturnType[]>,
    public silenceErrors = false,
    private cooldown: number = 200,
    private maxTimeout: number = 0, // must be greater than cooldown to take effect
  ) {
    this.debouncedExecute = debounce(this.executeFetch, this.cooldown);
  }

  async getByIds(ids: string[]) {
    if (ids.length === 0) return [];

    const promises = ids.map((id) => {
      const promise = new Promise<ReturnType>((resolve, reject) =>
        this.awaitingIds.push({ id, resolve, reject }),
      );
      if (this.silenceErrors)
        return promise.catch((e) => {
          console.error(e);
          return null;
        });
      return promise;
    });

    this.debouncedExecute();
    return await Promise.all(promises);
  }

  cancel() {
    if (this.timeout) clearTimeout(this.timeout);
    if (this.idleTimeout) clearTimeout(this.idleTimeout);
    this.awaitingIds.forEach((id) =>
      id.reject("Request cancelled from external source"),
    );
    this.awaitingIds.splice(0, this.awaitingIds.length);
  }

  private async executeFetch() {
    // reset the cooldown if a fetch hasnt executed yet
    if (this.timeout && !this.pending) {
      clearTimeout(this.timeout);
      if (this.idleTimeout) clearTimeout(this.idleTimeout);
    }

    // if were in the middle of a request, dont send another request
    if (this.pending) return;

    if (this.maxTimeout > this.cooldown)
      this.idleTimeout = setTimeout(async () => {
        if (this.timeout) clearTimeout(this.timeout);

        this.awaitingIds.forEach((id) => id.reject("Request timed out."));
      }, this.maxTimeout);

    this.timeout = setTimeout(async () => {
      if (this.idleTimeout) clearTimeout(this.idleTimeout);

      // consume ids being fetched
      const consumedIds = this.awaitingIds.splice(0, this.awaitingIds.length);
      if (consumedIds.length === 0) return;

      this.pending = true;

      // dedupe the ids here, but not for the functions that are awaiting returns
      const ids = Array.from(new Set(consumedIds.map((id) => id.id)));
      const res = await this.batchFn(ids);

      res.forEach((res: ReturnType) => {
        let foundIndex = consumedIds.findIndex((id) => id.id === res.id);
        while (foundIndex >= 0) {
          consumedIds[foundIndex].resolve(res);
          consumedIds.splice(foundIndex, 1);
          foundIndex = consumedIds.findIndex((id) => id.id === res.id);
        }
      });

      // if consumed ids are not found, reject them with generic 404
      consumedIds.forEach((id) => id.reject(`404 Not Found for id ${id.id}`));

      this.pending = false;

      // someone requested information while we were getting prev info
      if (this.awaitingIds.length > 0) {
        this.executeFetch();
      }
    }, this.cooldown);
  }
}
